import React, { Suspense, lazy } from 'react';
import Loading from './components/Loading/Loading';



const Routing = lazy(() => import("./pages/Routing/Routing"));

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routing />
    </Suspense>
  )
}

export default App